import {
  Instance,
  types
} from 'mobx-state-tree';

import { DeliveryDateModel } from './DeliveryDateModel';
import GroupModel from './GroupModel';
import MottoModel from './MottoModel';
import ProfileAsAuthorModel from './ProfileAsAuthorModel';
import SchoolModel from './SchoolModel';

export const CustomBindingTypesEnum = types.enumeration(['no_binding_type', 'softcover', 'hardcover']);

export type CustomBindingType = Instance<typeof CustomBindingTypesEnum>;

export const EditingStateEnum = types.enumeration([
  'onboarding',
  'preparing',
  'active',
  'revising',
  'generating',
  'previewing',
  'order_placed',
  // 'operating_partially',
  // 'operating_normally',
  // 'locked_by_organizer',
  // 'locked_by_system',
  'lock_detected' // will be set on error 423
]);
export type EditingState = Instance<typeof EditingStateEnum>;

export const DesignTicketStateEnum = types.enumeration([
  'no_ticket',
  'requested',
  'in_progress',
  'finished'
]);
export type DesignTicketState = Instance<typeof DesignTicketStateEnum>;

export const PagesStatusEnum = types.enumeration([
  'ok',
  'softcover_only',
  'hardcover_only',
  'too_many',
  'too_few'
]);

export const OrderStateEnum = types.enumeration([
  'open',
  'pending',
  'confirmed',
  'printing',
  'shipped',
  'done'
]);
export type OrderState = Instance<typeof OrderStateEnum>;

export const PriceTierEnum = types.enumeration([
  'default',
  'silver',
  'gold',
  'platinum_light',
  'platinum'
]);
export type PriceTier = Instance<typeof PriceTierEnum>;


const BookModel = types
  .model('BookModel', {
    id: types.identifierNumber,
    invitation_token: types.maybe(types.string),
    invitation_url: types.maybe(types.string),
    year: types.maybe(types.number),
    school: types.maybe(SchoolModel),
    number_of_students: types.maybe(types.number),
    motto: types.maybe(MottoModel),
    editing_state: types.maybe(EditingStateEnum),
    groups: types.maybe(types.map(GroupModel)),
    delivery_date: types.maybe(DeliveryDateModel),
    delivery_date_reservation_time: types.maybe(types.Date),
    groups_applied: types.maybe(types.boolean),
    allow_motto_vote: types.maybe(types.boolean),
    time_plan: types.maybe(types.Date),
    design_ticket_state: types.maybe(DesignTicketStateEnum),
    design_ticket_author: types.maybe(ProfileAsAuthorModel),
    textile_design_ticket_state: types.maybe(DesignTicketStateEnum),
    textile_design_ticket_author: types.maybe(ProfileAsAuthorModel),
    inquired_sample: types.maybe(types.boolean),

    // print stuff
    may_create_pdf: types.maybe(types.boolean),
    pdf_pages_count: types.maybe(types.number),
    rounded_pdf_pages_count: types.maybe(types.number),
    pdf_pages_status: types.maybe(PagesStatusEnum),
    preview_pdf: types.maybe(types.string),
    preview_pdf_size: types.maybe(types.number),
    print_preview_pdf: types.maybe(types.string),
    print_preview_pdf_size: types.maybe(types.number),
    inside_pdf: types.maybe(types.string),
    inside_pdf_size: types.maybe(types.number),
    hardcover_pdf: types.maybe(types.string),
    hardcover_pdf_size: types.maybe(types.number),
    softcover_pdf: types.maybe(types.string),
    softcover_pdf_size: types.maybe(types.number),

    // order state
    order_state: types.maybe(OrderStateEnum),

    // profiles count
    profiles_count: types.maybe(types.number),
    manager_name: types.maybe(types.string),

    // price tiers
    price_tier: types.maybe(PriceTierEnum),
    custom_pdf_pages_count: types.maybe(types.number),
    custom_binding_type: types.maybe(CustomBindingTypesEnum),
    custom_sponsor_count: types.maybe(types.number),

    // PDF regeneration
    needs_pdf_regeneration: types.maybe(types.boolean)
  })
  .views((self) => {
    return {
      get isLockedForOrder(): boolean {
        return self.editing_state === 'order_placed';
      },
      get isLockedForPreview(): boolean {
        return (
          self.editing_state === 'generating' ||
          self.editing_state === 'previewing' ||
          self.editing_state === 'order_placed'
        );
      },
      get isPreviewReady(): boolean {
        return (self.editing_state === 'previewing' || self.editing_state === 'order_placed') && !!self.preview_pdf;
      },
      get isCheckoutReady(): boolean {
        if (self.price_tier === 'gold' || self.price_tier === "platinum_light" || self.price_tier === "platinum") {
          // @ts-ignore
          return self.isPreviewReady;
        } else {
          return false;
        }
      },
      get checkoutPdfPagesCount(): number | undefined {
        if (self.price_tier === 'gold' || self.price_tier === "platinum_light" || self.price_tier === "platinum") {
          return self.rounded_pdf_pages_count
        } else {
          return self.custom_pdf_pages_count
        }
      },

      get orderAtLeastConfirmed(): boolean {
        switch (self.order_state) {
          case 'confirmed':
          case 'printing':
          case 'shipped':
          case 'done':
            return true;

          default:
            return false;
        }
      },
      get orderAtLeastPaymentOpen(): boolean {
        switch (self.order_state) {
          case 'pending':
          case 'confirmed':
          case 'printing':
          case 'shipped':
          case 'done':
            return true;

          default:
            return false;
        }
      },

      get hasDesignTicket(): boolean {
        return (
          self.design_ticket_state === 'requested' ||
          self.design_ticket_state === 'in_progress' ||
          self.design_ticket_state === 'finished'
        );
      },
      get hasActiveDesignTicket(): boolean {
        return (
          self.design_ticket_state === 'requested' ||
          self.design_ticket_state === 'in_progress'
        );
      },
      get hasFinishedDesignTicket(): boolean {
        return self.design_ticket_state === 'finished';
      },

      get hasActiveGroups(): boolean {
        return self.groups?.size && self.groups_applied ? true : false;
      },

      get priceTierAtLeastGold(): boolean {
        switch (self.price_tier) {
          case 'gold':
          case 'platinum':
          case 'platinum_light':
            return true;

          default:
            return false;
        }
      },
      get silverReadyForCheckout(): boolean {
        return self.price_tier === 'silver' && self.custom_pdf_pages_count !== undefined && self.custom_pdf_pages_count > 0 && self.custom_binding_type !== 'no_binding_type';
      }
    };
  });

export type BookModelType = Instance<typeof BookModel>;
export default BookModel;
