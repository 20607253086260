import BookModel, {
  BookModelType,
  CustomBindingTypesEnum,
  DesignTicketStateEnum,
  EditingStateEnum,
  OrderStateEnum,
  PagesStatusEnum,
  PriceTierEnum
} from 'models/BookModel';
import { createGroupModel } from 'models/GroupModel';
import { createMottoModel } from 'models/MottoModel';
import { createProfileAsAuthorModel } from 'models/ProfileAsAuthorModel';
import { createMapWithTransform } from 'utils/create-map';

import booleanOrUndefined from './booleanOrUndefined';
import createDeliveryDateModel from './createDeliveryDateModel';
import createSchoolModel from './createSchoolModel';
import mandatoryId from './mandatoryId';
import numberOrUndefined from './numberOrUndefined';

const createBookModel = (book: any): BookModelType | undefined => {
  if (!book) {
    return undefined;
  }

  return BookModel.create({
    id: mandatoryId(book.id),
    invitation_token: book.invitation_token || undefined,
    invitation_url: book.invitation_url || undefined,
    editing_state: EditingStateEnum.is(book.editing_state)
      ? book.editing_state
      : undefined,
    motto: !book.motto ? undefined : createMottoModel(book.motto),
    school: !book.school ? undefined : createSchoolModel(book.school),
    year: numberOrUndefined(book.year),
    number_of_students: numberOrUndefined(book.number_of_students),
    groups: !book.groups
      ? undefined
      : createMapWithTransform(book.groups, createGroupModel),
    delivery_date: createDeliveryDateModel(book.delivery_date),
    delivery_date_reservation_time: new Date(
      book.delivery_date_reservation_time
    ),
    groups_applied: booleanOrUndefined(book.groups_applied),
    allow_motto_vote: booleanOrUndefined(book.allow_motto_vote),

    time_plan: book.time_plan ? new Date(book.time_plan) : undefined,
    design_ticket_state: DesignTicketStateEnum.is(book.design_ticket_state)
      ? book.design_ticket_state
      : undefined,
    design_ticket_author: createProfileAsAuthorModel(book.design_ticket_author),
    textile_design_ticket_state: DesignTicketStateEnum.is(
      book.textile_design_ticket_state
    )
      ? book.textile_design_ticket_state
      : undefined,
    textile_design_ticket_author: createProfileAsAuthorModel(
      book.textile_design_ticket_author
    ),
    inquired_sample: booleanOrUndefined(book.inquired_sample),

    may_create_pdf: booleanOrUndefined(book.may_create_pdf),
    pdf_pages_count: numberOrUndefined(book.pdf_pages_count),
    rounded_pdf_pages_count: numberOrUndefined(book.rounded_pdf_pages_count),
    pdf_pages_status: PagesStatusEnum.is(book.pdf_pages_status)
      ? book.pdf_pages_status
      : undefined,
    preview_pdf: book.preview_pdf || undefined,
    preview_pdf_size: numberOrUndefined(book.preview_pdf_size),
    print_preview_pdf: book.print_preview_pdf || undefined,
    print_preview_pdf_size: numberOrUndefined(book.print_preview_pdf_size),
    order_state: OrderStateEnum.is(book.order_state)
      ? book.order_state
      : undefined,
    inside_pdf: book.inside_pdf || undefined,
    inside_pdf_size: numberOrUndefined(book.inside_pdf_size),
    hardcover_pdf: book.hardcover_pdf || undefined,
    hardcover_pdf_size: numberOrUndefined(book.hardcover_pdf_size),
    softcover_pdf: book.softcover_pdf || undefined,
    softcover_pdf_size: numberOrUndefined(book.softcover_pdf_size),
    profiles_count: book.profiles_count || undefined,
    manager_name: book.manager_name || undefined,
    price_tier: PriceTierEnum.is(book.price_tier)
    ? book.price_tier
    : undefined,
    custom_pdf_pages_count: numberOrUndefined(book.custom_pdf_pages_count),
    custom_binding_type: CustomBindingTypesEnum.is(book.custom_binding_type)
    ? book.custom_binding_type
    : undefined,
    custom_sponsor_count: numberOrUndefined(book.custom_sponsor_count),
    needs_pdf_regeneration: booleanOrUndefined(book.needs_pdf_regeneration)
  });
};

export default createBookModel;
