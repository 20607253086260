import styled from 'styled-components';

import SPACES from 'utils/constants/spaces';

export const HelpButtonOverlay = styled.div`
  position: fixed;
  bottom: ${SPACES.M};
  margin: ${SPACES.L} 0 0 0;
  align-self: flex-end;
  z-index: 80;
`;

export default HelpButtonOverlay;
