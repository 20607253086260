import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { HasAccessProvider } from 'contexts/HasAccessContext';
import { useTextileAppStateQuery } from 'queries/textile_deals/useTextileAppStateQueries';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import AccountDashboardScreen from 'screens/account_dashboard/AccountDashboardScreen';
import {
  ROUTE_LOGIN,
  ROUTE_TEXTILE_ORDER
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';

const TextileRoutes: React.FC<HistoryProps> = ({ match, history }) => {
  const textileOrderId = textileOrderIdFromMatch(match);
  const { data, isError, isLoading, isFetching } = useTextileAppStateQuery(
    textileOrderId
  );

  if (!textileOrderId) {
    return null;
  }

  if (isError) {
    history.push(ROUTE_LOGIN);
  }

  if (isLoading) {
    return (
      <>
        <HeaderComponent />
        <LoadingOverlayComponent />
      </>
    );
  }

  if (isFetching) {
    return (
      <>
        <HeaderComponent />
        <LoadingOverlayComponent />
      </>
    );
  }

  return (
    <HasAccessProvider textileAppState={data} history={history} match={match}>
      <Switch>

        <Route path={ROUTE_TEXTILE_ORDER} component={AccountDashboardScreen} />

        {/* <Route
          path={ROUTE_TEXTILE_ORDER + '/join_textile'}
          component={JoinTextileScreen}
        />

        <Route
          path={ROUTE_TEXTILE_ONBOARDING}
          component={TextileOnboardingRouter}
        />

        <Route
          path={ROUTE_TEXTILE_ORDER + '/account'}
          component={AccountScreen}
        />

        <Route
          path={ROUTE_TEXTILE_DESIGN}
          component={TextileDesignSettingsRouter}
        />

        <Route
          path={ROUTE_TEXTILE_DESIGN_GROUPS}
          component={TextileGroupsRouter}
        />

        <Route
          path={ROUTE_TEXTILE_MANAGEMENT}
          component={TextileManagementRouter}
        />

        <Route
          path={ROUTE_TEXTILE_PROFILES}
          component={TextileProfilesRouter}
        />

        <Route
          path={ROUTE_TEXTILE_ORDER + '/discounts'}
          component={TextileDiscountsScreen}
        />

        <Route path={ROUTE_TEXTILE_PREORDER} component={PreOrderRouter} />

        <Route
          path={ROUTE_TEXTILE_ORDER_OVERVIEW}
          component={TextileOrderRouter}
        />

        <Route
          path={ROUTE_TEXTILE_CALCULATOR}
          component={TextileCalculatorRouter}
        />

        <Route
          path={ROUTE_TEXTILE_CHECKOUT}
          component={TextileCheckoutRouter}
        />

        <Route
          path={ROUTE_TEXTILE_ORDER}
          exact={true}
          component={TextileDashboardScreen}
        /> */}

        <NotFoundRoute />
      </Switch>
    </HasAccessProvider>
  );
};

export default TextileRoutes;