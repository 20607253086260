import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import React, { createContext, useEffect, useState } from 'react';
import { SectionType, hasAccess } from 'utils/hasAccess';
import { HistoryWithQueryParams } from 'utils/history';
import { textileOrderRoute } from 'utils/history/textile-order-route';

interface AccessContextValue {
  checkSectionAccess: (section: SectionType) => void;
}

export const HasAccessContext = createContext<AccessContextValue | undefined>(undefined);

export function HasAccessProvider({ children, textileAppState, history, match } : { children: JSX.Element, textileAppState?: TextileAppState, history: HistoryWithQueryParams, match: any}) {
  const [section, setSection] = useState<SectionType | undefined>();

  // TODO: needs to be evaluated if this is the right place, more logic to come
  useEffect(() => {
    if (textileAppState && textileAppState.textile_order.order_state === 'onboarding' && textileAppState.textile_profile.is_manager) {
      return history.replace(textileOrderRoute(match, '/onboarding/'));
    }

    if (textileAppState && !textileAppState.textile_profile.join_role_selected) {
      return history.replace(textileOrderRoute(match, '/join_textile/'));
    }

    if (section && !hasAccess(section, textileAppState)) {
      return history.replace(textileOrderRoute(match, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, textileAppState]);

  const checkSectionAccess = (section: SectionType) => {
    setSection(section);
};


  return (
    <HasAccessContext.Provider value={{checkSectionAccess}}>
      {children}
    </HasAccessContext.Provider>
  );
}
