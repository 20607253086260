import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { ApplicationStoreType } from 'models/ApplicationStore';
import ButtonBlock from 'blocks/ButtonBlock';
import IconComponent from 'components/IconComponent';
import HelpButtonOverlay from './HelpButtonOverlay';
import { ROUTE_HELP } from 'utils/constants/routes';

interface HelpButtonProps {
  noOverlay?: boolean;
  applicationStore?: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class HelpButton extends React.Component<
  HelpButtonProps & RouteComponentProps
> {
  private open() {
    const { helpSubject } = this.props.applicationStore!;
    const { pathname, search } = this.props.location;

    this.props.history.push(ROUTE_HELP + '/' + (helpSubject || 'general'), {
      referrer: pathname + (search ? '?' + search : '')
    });
  }

  render() {
    const { applicationStore, noOverlay } = this.props;
    const { helpHidden, helpHiddenByChild, isOrganizer } = applicationStore!;

    if (helpHidden || helpHiddenByChild) {
      return null;
    }

    const button = (
      <ButtonBlock
        inline={true}
        slim={true}
        round={true}
        iconRight={true}
        background="SECONDARY_DARK"
        onClick={() => this.open()}
      >
        <FormattedMessage
          id={isOrganizer ? 'help button organizer' : 'help button student'}
        />
        <IconComponent icon="QUESTIONMARK_CIRCLE" fill="WHITE" size={1.125} />
      </ButtonBlock>
    );

    if (noOverlay) {
      return button;
    }

    return <HelpButtonOverlay>{button}</HelpButtonOverlay>;
  }
}

export default withRouter(HelpButton);
