import React, { Component } from 'react';

import AccountRoutes from 'AccountRoutes';
import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import HelpButton from 'components/HelpButton/HelpButton';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { Route, Switch } from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import AlbumPagesRouter from 'screens/album_pages/AlbumPagesRouter';
import CalculatorScreen from 'screens/calculator/Calculator';
import CheckoutAuthorChangeScreen from 'screens/calculator/screens/CheckoutAuthorChangeScreen';
import OfferScreen from 'screens/calculator/screens/OfferScreen';
import CheckoutScreen from 'screens/checkout/screens/CheckoutScreen';
import CommentsRouter from 'screens/comments/CommentsRouter';
import ContentDashboardRouter from 'screens/dashboards/ContentDashboardRouter';
import MainDashboardScreen from 'screens/dashboards/MainDashboardScreen/MainDashboardScreen';
import DiscountScreen from 'screens/dashboards/discounts/DiscountScreen';
import SponsorDiscountsScreen from 'screens/dashboards/discounts/SponsorDiscountsScreen';
import DeliveryDatesOverviewScreen from 'screens/delivery_dates/DeliveryDatesOverviewScreen';
import FactsheetsRouter from 'screens/factsheets/FactsheetsRouter';
import LayoutRouter from 'screens/layout/LayoutRouter';
import MottosRouter from 'screens/mottos/MottosRouter';
import OnboardingRouter from 'screens/onboarding/OnboardingRouter';
import OrderOverview from 'screens/order/screens/OrderOverview';
import OrderScreen from 'screens/order/screens/OrderScreen';
import PhotosRouter from 'screens/photos/PhotosRouter';
import QuotesRouter from 'screens/quotes/QuotesRouter';
import RankingsRouter from 'screens/rankings/RankingsRouter';
import SettingsRouter from 'screens/settings/SettingsRouter';
import SponsorPagesRouter from 'screens/sponsor_pages/SponsorPagesRouter';
import SponsorsRouter from 'screens/sponsors/SponsorsRouter';
import TextsRouter from 'screens/texts/TextsRouter';
// import TimeplanRouter from 'screens/timeplan/TimeplanRouter';
import TocRouter from 'screens/toc/TocRouter';
import YearbooksRouter from 'screens/yearbooks/YearbooksRouter';
import {
  ROUTE_ACCOUNT_DASHBOARD,
  ROUTE_ALBUMS,
  ROUTE_ALBUM_PAGES,
  ROUTE_BOOK,
  ROUTE_CALCULATOR,
  ROUTE_CHECKOUT,
  ROUTE_CHECKOUT_CHANGE_AUTHOR,
  ROUTE_COMMENTS,
  ROUTE_CONTENT,
  ROUTE_DELIVERY_DATES_OVERVIEW,
  ROUTE_DISCOUNTS,
  ROUTE_FACTSHEETS,
  ROUTE_LAYOUT,
  ROUTE_MOTTOS,
  ROUTE_OFFER,
  ROUTE_ONBOARDING,
  ROUTE_ORDER,
  ROUTE_ORDER_OVERVIEW,
  ROUTE_QUOTES,
  ROUTE_RANKINGS,
  ROUTE_SETTINGS,
  ROUTE_SPONSORS,
  ROUTE_SPONSOR_PAGES,
  ROUTE_TEXTS,
  ROUTE_TOC,
  ROUTE_YEARBOOKS
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface BookRoutesProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class BookRoutes extends Component<BookRoutesProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore } = this.props;

    if (!applicationStore.currentUser) {
      applicationStore.checkAuthenticatedBook(true);
    }

    if (applicationStore.currentUser) {
      applicationStore.checkForBookOnboarding();
    }
  }

  renderLoader() {
    return (
      <>
        <HeaderComponent />
        <LoadingOverlayComponent />
      </>
    );
  }

  render() {
    const { applicationStore } = this.props;

    if (applicationStore.isAuthenticating) {
      return this.renderLoader();
    }

    if (!applicationStore.isAuthenticated) {
      // this should not happen as ApplicationStore redirects to login
      // but just to be sure
      return this.renderLoader();
    }

    return (
      <>
        <Switch>
          <Route path={ROUTE_ONBOARDING} component={OnboardingRouter} />
          <Route path={ROUTE_SETTINGS} component={SettingsRouter} />

          <Route path={ROUTE_QUOTES} component={QuotesRouter} />
          <Route path={ROUTE_COMMENTS} component={CommentsRouter} />
          <Route path={ROUTE_RANKINGS} component={RankingsRouter} />
          <Route path={ROUTE_SPONSOR_PAGES} component={SponsorPagesRouter} />
          <Route path={ROUTE_SPONSORS} component={SponsorsRouter} />
          <Route path={ROUTE_TEXTS} component={TextsRouter} />
          <Route path={ROUTE_ALBUM_PAGES} component={AlbumPagesRouter} />
          <Route path={ROUTE_ALBUMS} component={PhotosRouter} />
          <Route path={ROUTE_FACTSHEETS} component={FactsheetsRouter} />
          <Route path={ROUTE_YEARBOOKS} component={YearbooksRouter} />
          <Route path={ROUTE_MOTTOS} component={MottosRouter} />
          {/* <Route path={ROUTE_TIMEPLAN} component={TimeplanRouter} /> */}
          <Route path={ROUTE_TOC} component={TocRouter} />
          <Route path={ROUTE_LAYOUT} component={LayoutRouter} />

          <Route path={ROUTE_CHECKOUT} component={CheckoutScreen} />
          <Route
            path={ROUTE_CHECKOUT_CHANGE_AUTHOR}
            component={CheckoutAuthorChangeScreen}
          />
          <Route path={ROUTE_OFFER} component={OfferScreen} />
          <Route path={ROUTE_ORDER} component={OrderScreen} />
          <Route path={ROUTE_ORDER_OVERVIEW} component={OrderOverview} />

          <Route path={ROUTE_CALCULATOR} component={CalculatorScreen} />

          <Route
            path={ROUTE_DELIVERY_DATES_OVERVIEW}
            component={DeliveryDatesOverviewScreen}
          />

          <Route
            path={ROUTE_DISCOUNTS + '/sponsors/:id'}
            component={SponsorDiscountsScreen}
          />
          <Route
            path={ROUTE_DISCOUNTS + '/sponsors'}
            component={SponsorDiscountsScreen}
          />
          <Route path={ROUTE_DISCOUNTS + '/:type'} component={DiscountScreen} />

          <Route path={ROUTE_ACCOUNT_DASHBOARD} component={AccountRoutes} />

          <Route path={ROUTE_CONTENT} component={ContentDashboardRouter} />
          <Route
            path={ROUTE_BOOK}
            exact={true}
            component={MainDashboardScreen}
          />
          <NotFoundRoute />
        </Switch>

        <HelpButton />
      </>
    );
  }
}

export default BookRoutes;
